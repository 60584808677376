<template>
    <!--begin::Card-->
    <div class="card card-custom card-stretch gutter-b">
        <div class="card-body">
            <div class="p-4">
                <div class="d-flex justify-content-between align-items-center mb-8">
                    <!--begin::Heading-->
                    <h2 class="text-dark">Description</h2>
                    <!--end::Heading-->
                </div>
                <!--begin::Content-->
                <div class="text-dark font-size-lg" v-html="nl2br(description)"></div>
                <!--end::Content-->
            </div>
        </div>
    </div>
    <!--begin::Card-->
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import nl2brMixin from "@/mixins/nl2br";

export default {
    name: 'widget11',
    props: {
        corpId: String
    },
     mixins: [nl2brMixin],
    data() {
        return {
            description: ''
        }
    },
    mounted() {
        this.$store.dispatch("getCorpDetails", { corpId: this.$route.params.corpId });
    },
    computed: {
        ...mapGetters(["corpDetails"])
    },
    watch: {
        corpDetails(newValue) {
            this.description = newValue.description;
        }
    }
}
</script>
