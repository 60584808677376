<template>
    <!--begin::Card-->
    <div class="card card-custom card-stretch gutter-b">
        <div class="card-body">
            <div class="p-6">
                <h2 class="text-dark mb-8">FAQ</h2>
                <!--begin::Accordion-->
                <div class="accordion accordion-light accordion-light-borderless accordion-svg-toggle"
                    id="accordionExample8">
                    <!--begin::Item-->
                    <div class="card">
                        <!--begin::Header-->
                        <div class="card-header" v-for="(subItem, subIndex) in faq" :key="subIndex">
                            <div class="card-title" data-toggle="collapse" data-target="#collapseOne8"
                                aria-expanded="true" aria-controls="collapseOne8" role="button" v-if="subItem.online">
                                <span class="svg-icon svg-icon-primary">
                                    <!--begin::Svg Icon | path:assets/media/svg/icons/Navigation/Angle-double-right.svg-->

                                    <!--end::Svg Icon-->
                                </span>
                                <div class="card-label text-dark pl-4"
                                    v-b-toggle.collapse="'collapse-' + subIndex + '_' + subIndex">
                                    {{ subItem.question }}
                                </div>
                            </div>
                        
                        <!--end::Header-->
                        <!--begin::Body-->
                        <b-collapse :id="'collapse-' + subIndex + '_' + subIndex">
                            <div class="card-body text-dark font-size-lg pl-12">
                                {{ subItem.answer }}
                            </div>
                        </b-collapse>
                        </div>
                        <!--end::Body-->
                    </div>
                    <!--end::Item-->
                </div>
                <!--end::Accordion-->
            </div>
        </div>
    </div>

    <!--begin::Card-->

    <!--begin::Card-->
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import nl2brMixin from "@/mixins/nl2br";

export default {
    name: 'widget10',
    props: {
        corpId: String
    },
    mixins: [nl2brMixin],
    data() {
        return {
            faq: ''
        }
    },
    mounted() {
        this.$store.dispatch("getCorpDetails", { corpId: this.$route.params.corpId });
    },
    computed: {
        ...mapGetters(["corpDetails"])
    },
    watch: {
        corpDetails(newValue) {
            let listSort = newValue.faq.sort((a, b) => (a.sortorder > b.sortorder) ? 1 : -1)
            this.faq = listSort
        }
    }
}
</script>
