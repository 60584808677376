<template>
    <!--begin::Card-->
    <div class="card card-custom gutter-b">
        <div class="card-body">
            <!--begin::Details-->
            <div class="flex-grow-1 mb-5">
                <div class="d-flex justify-content-between flex-wrap mt-1">
                    <div class="d-flex mr-3">
                        <img :src="companyImageSource(corpImage)" class="mh-75px obcover br-42 align-self-center"
                            alt="" />
                    </div>
                    <div class="my-lg-0 my-3">
                        <router-link :to="{ name: 'corporateedit', params: { corpId: currentCorpId } }"
                            class="label label-xl label-inline label-light-danger mr-2">
                            Edit
                        </router-link>
                        <button @click="changeCorpStatus(false)" v-if="onlineStatus == true"
                            class="label label-xl label-inline label-light-success mr-2" :disabled="onlineDisabled">
                            Online
                        </button>
                        <button @click="changeCorpStatus(true)" v-else
                            class="label label-xl label-inline label-light-danger mr-2" :disabled="onlineDisabled">
                            Offline
                        </button>
                        <a :href="companyURL" target="_blank"
                            class="label label-xl label-inline label-light-ingomublue">Registration Page</a>
                    </div>
                </div>
            </div>
            <div class="d-flex mb-9">
                <!--begin::Info-->
                <div class="flex-grow-1">
                    <!--begin::Title-->
                    <div class="d-flex justify-content-between flex-wrap mt-1">
                        <div class="d-flex mr-3">
                            <a href="javascript:void(0)"
                                class="text-dark text-hover-primary font-size-h5 font-weight-bold mr-3">{{ corpName
                                }}</a>
                        </div>
                    </div>
                    <!--end::Title-->
                </div>
                <!--end::Info-->
            </div>
            <!--end::Details-->
            <div class="separator separator-solid"></div>
            <!--begin::Items-->
            <div class="d-flex align-items-center flex-wrap mt-8">
                <!--begin::Item-->
                <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2" v-if="!isPaid">
                    <span class="mr-4">
                        <i class="flaticon-users display-4 text-dark font-weight-bold"></i>
                    </span>
                    <div class="d-flex flex-column text-dark">
                        <span class="font-weight-bolder font-size-sm">Total</span>
                        <span class="font-weight-bolder font-size-h5">
                            {{ totalNumber }}
                        </span>
                    </div>
                </div>
                <!--end::Item-->
                <!--begin::Item-->
                <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2" v-if="!isPaid">
                    <span class="mr-4">
                        <i class="flaticon2-add display-4 text-dark font-weight-bold"></i>
                    </span>
                    <div class="d-flex flex-column text-dark">
                        <span class="font-weight-bolder font-size-sm">Available</span>
                        <span class="font-weight-bolder font-size-h5">
                            {{ totalActive }}
                        </span>
                    </div>
                </div>
                <!--end::Item-->
                <!--begin::Item-->
                <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2" v-if="isPaid">
                    <span class="mr-4">
                        <i class="flaticon-piggy-bank display-4 text-dark font-weight-bold"></i>
                    </span>
                    <div class="d-flex flex-column text-dark">
                        <span class="font-weight-bolder font-size-sm">Semi-Annual / Annual</span>
                        <span class="font-weight-bolder font-size-h5">
                            &dollar;{{ semi }} / &dollar;{{ price }}
                        </span>
                    </div>
                </div>
                <!--end::Item-->

                <!--begin::Item-->
                <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                    <span class="mr-4">
                        <i class="flaticon2-line display-4 text-dark font-weight-bold"></i>
                    </span>
                    <div class="d-flex flex-column text-dark">
                        <span class="font-weight-bolder font-size-sm">Used</span>
                        <span class="font-weight-bolder font-size-h5">
                            {{ totalUsed }}
                        </span>
                    </div>
                </div>
                <!--end::Item-->
                <!--begin::Item-->
                <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                    <span class="mr-4">
                        <i class="flaticon-calendar-1 display-4 text-dark font-weight-bold"></i>
                    </span>
                    <div class="d-flex flex-column flex-lg-fill">
                        <span class="text-dark font-weight-bolder font-size-sm">Joined</span>
                        {{ shortFormatDate(startdate) }}
                    </div>
                </div>
                <!--end::Item-->
            </div>
            <!--begin::Items-->
        </div>
    </div>
    <!--end::Card-->

</template>

<script>
import { mapActions, mapGetters } from "vuex";
import formateDateMixin from "@/mixins/formatDate";
import defaultProfileImageMixin from "@/mixins/defaultProfileImage";
import Swal from "sweetalert2";

export default {
    name: 'widget9',
    mixins: [defaultProfileImageMixin, formateDateMixin],
    props: {
        corpId: String
    },
    data() {
        return {
            corpImage: "",
            corpName: "",
            totalNumber: 0,
            totalActive: 0,
            totalUsed: 0,
            startdate: "",
            onlineStatus: true,
            onlineDisabled: false,
            isPaid: false,
            price: 0,
            semi: 0,
            slug: 'eventinterface'
        }
    },
    mounted() {
        this.$store.dispatch("getCorpDetails", { corpId: this.$route.params.corpId });
    },
    computed: {
        ...mapGetters(["corpDetails"]),
        currentCorpId() {
            return this.$route.params.corpId;
        },
        companyURL() {
            return "https://ingomu.com/" + this.slug
        }
    },
    methods: {
        changeCorpStatus(status) {
            Swal.fire({
                title: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3b5998",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, change it!",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.onlineDisabled = true
                    this.textStatus = status
                    this.$store.dispatch("updateCorpOnlineStatus", {
                        status: status,
                        corpid: this.$route.params.corpId
                    });
                    Swal.fire("Processing!", "The online status will update shortly. This will disable the signup form but not invalidate the unused codes. You must do that in the individual code sections.", "success");
                }
            });
        },
    },
    watch: {
        corpDetails(newValue) {
            this.corpImage = newValue.corpimage;
            this.corpName = newValue.name;
            this.totalNumber = newValue.number_licenses;
            this.totalActive = newValue.total_active;
            this.totalUsed = newValue.total_used;
            this.startdate = newValue.startdate;
            this.onlineStatus = newValue.online;
            this.isPaid = newValue.isPaid
            this.price = newValue.price
            this.semi = newValue.semi
            this.slug = newValue.slug
        }
    }
}
</script>

<style scoped>
.mh-75px {
    max-height: 75px !important;
}

.label.label-light-ingomublue {
    color: #FFF;
    background-color: #3b5998;
}
</style>
