<template>
    <div class="row">
        <div class="col-xl-12">
            <div class="card card-custom card-stretch gutter-b">
                <!--begin::Header-->
                <div class="card-header border-0 pt-5">
                    <h3 class="card-title align-items-start flex-column">
                        <span class="card-label font-weight-bolder text-dark fs-15">Paid Users</span>
                    </h3>
                </div>
                <div class="card-body pt-2 pb-0">
                    <b-table id="latest-sessions-table" :items="paidList" :fields="fields" :per-page="perPage"
                        :current-page="currentPage" show-empty
                        class="table table-borderless table-vertical-center obcover br-42 align-self-center">
                        <template #cell(image)="data" class="pl-0 py-4">
                            <div class="symbol symbol-50 symbol-light mr-1">
                                <span class="symbol-label">
                                    <a href="javascript:void(0)"
                                        class="symbol-label text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">
                                        <img :src="imageSource(data.item.user.profileimage)"
                                            class="h-100 w-100 obcover br-42 align-self-center" alt=""
                                            v-if="data.item.user !== undefined" />
                                        <img :src="imageSource(null)"
                                            class="h-100 w-100 obcover br-42 align-self-center" alt="" v-else />
                                    </a>
                                </span>
                            </div>
                        </template>
                        <template #cell(name)="data" class="pl-0 py-4">
                            <a href="javascript:void(0)" class="text-dark font-weight-bold d-block"
                                v-if="'user' in data.item">{{ data.item.user.email }}</a>
                        </template>
                        <template #cell(expiredate)="data" class="pl-0 py-4">
                            <a href="javascript:void(0)">
                                {{ shortFormatDate(data.item.expiration_date) }}
                            </a>
                        </template>
                        <template #cell(redeemdate)="data" class="pl-0 py-4">
                            <a href="javascript:void(0)">
                                {{ shortFormatDate(data.item.redeem_date) }}
                            </a>
                        </template>
                        <template #cell(amount)="data" class="pl-0 py-4">
                            <a href="javascript:void(0)">
                                &dollar;{{ data.item.amount }}
                            </a>
                        </template>
                        <template #cell(orderid)="data" class="pl-0 py-4">
                            <a href="javascript:void(0)">
                                {{ data.item.orderid }}
                            </a>
                            <br />
                            <a href="javascript:void(0)">
                                {{ data.item.payerid }}
                            </a>
                        </template>
                        <template #empty="scope">
                            <div class="text-center mt-10">
                                <h5>There are no paid users yet!</h5>
                            </div>
                        </template>
                    </b-table>
                </div>
                <b-card-footer>
                    <div class="row mt-7 mb-7">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="left">
                        </b-pagination>
                    </div>
                </b-card-footer>
            </div>
        </div>

    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import defaultProfileImageMixin from "@/mixins/defaultProfileImage";
import formateDateMixin from "@/mixins/formatDate";

export default {
    name: 'widget16',
    mixins: [defaultProfileImageMixin, formateDateMixin],
    props: {
        corpId: String
    },
    data() {
        return {
            perPage: 10,
            currentPage: 1,
            fields: [
                { key: "image", label: "" },
                { key: "name", label: "Email" },
                { key: "redeemdate", label: "Redeemed Date" },
                { key: "expiredate", label: "Expiration Date" },
                { key: "amount", label: "Amount" },
                { key: "orderid", label: "Orderid/Payerid" }
            ],
        }
    },
    mounted() {
        this.$store.dispatch("getPaidUsers", { corpid: this.$route.params.corpId });
    },
    computed: {
        ...mapGetters(["paidList"]),
        rows() {
            return this.paidList.length;
        },
    },
}
</script>