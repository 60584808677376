<template>
    <div class="d-flex flex-column-fluid">
        <!--begin::Container-->
        <div class="container-fluid py-8">
            <div class="d-flex flex-row">
                <!--begin::Content-->
                <div class="flex-row-fluid ">
                    <div class="row">
                        <div class="col-xl-12">
                            <ListWidget9 corpid></ListWidget9>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-8">
                            <ListWidget10 corpid></ListWidget10>
                        </div>
                        <div class="col-lg-4">
                            <ListWidget11 corpid></ListWidget11>
                        </div>
                    </div>
                    <div class="row" v-if="!isPaid">
                        <div class="col-lg-12">
                            <ListWidget14 corpid></ListWidget14>
                        </div>
                    </div>
                    <div class="row" v-else>
                        <div class="col-lg-12">
                            <ListWidget16 corpid></ListWidget16>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ListWidget9 from "@/view/content/widgets/admin/Widget9.vue";
import ListWidget10 from "@/view/content/widgets/admin/Widget10.vue";
import ListWidget11 from "@/view/content/widgets/admin/Widget11.vue";
import ListWidget14 from "@/view/content/widgets/admin/Widget14.vue";
import ListWidget16 from "@/view/content/widgets/admin/Widget16.vue";
import { mapActions, mapGetters } from "vuex";

export default {
    name: 'corporatedetail',
    components: {
        ListWidget9,
        ListWidget10,
        ListWidget11,
        ListWidget14,
        ListWidget16
    },
    data() {
        return {
            isPaid: false
        }
    },
    mounted() {
        this.$store.dispatch("getCorpDetails", { corpId: this.$route.params.corpId });
    },
    computed: {
        ...mapGetters(["corpDetails"]),
        corpid() {
            return this.$route.params.corpId
        },
    },
    watch: {
        corpDetails(newValue) {
            this.isPaid = newValue.isPaid
        }
    }

}
</script>
