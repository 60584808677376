<template>
    <!--begin::Card-->
    <div class="card card-custom card-stretch gutter-b">
        <div class="card-body">
            <div class="p-4">
                <div class="d-flex justify-content-between align-items-center mb-8">
                    <!--begin::Heading-->
                    <h2 class="text-dark">Promo Codes</h2>
                    <div class="my-lg-0 my-3">
                        <router-link :to="{ name: 'codegenerate', params: { corpId: corpid } }" class="nav-link btn-ingomublue py-2 px-4 active btn-sm">
                            New
                        </router-link>
                    </div>
                    <!--end::Heading-->
                </div>
                <!--begin::Content-->
                <b-table striped hover id="code-table" :items="codeGroupList" :fields="fields" :per-page="perPage"
                    :current-page="currentPage" show-empty class="table table-borderless table-vertical-center">
                    <template #cell(created)="data" class="pl-0 py-4">
                        <span class="text-dark font-weight-bolder d-block font-size-lg">{{ shortFormatDate(data.item.created) }}</span>
                    </template>
                    <template #cell(totallicenses)="data" class="pl-0 py-4">
                        <span class="text-dark font-weight-bolder d-block font-size-lg">{{ data.item.number_licenses }}</span>
                    </template>
                    <template #cell(used)="data" class="pl-0 py-4">
                        <span class="text-dark font-weight-bolder d-block font-size-lg">{{ data.item.total_used }}</span>
                    </template>
                    <template #cell(online)="data" class="pl-0 py-4">
                        <span class="label label-lg label-light-success label-inline"
                            v-if="data.item.online">Online</span>
                        <span class="label label-lg label-light-danger label-inline" v-else>Offline</span>
                    </template>
                    <template #cell(status)="data" class="pl-0 py-4">
                         <router-link :to="{ name: 'corporatecodes', params: { corpId: data.item.corpid, codegroupId: data.item.id } }" class="btn btn-icon btn-ingomublue btn-hover-primary btn-xs">
                        <i class="la la-arrow-right icon-xl text-white"></i>
                    </router-link>
                    </template>
                </b-table>
                <!--end::Content-->
            </div>
        </div>
         <!-- begin: footer -->
        <div class="card-footer">
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="left">
            </b-pagination>
        </div>
        <!-- end: footer -->
    </div>
    <!--begin::Card-->
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import formateDateMixin from "@/mixins/formatDate";

export default {
    name: 'widget14',
    mixins: [formateDateMixin],
    props: {
        corpId: String
    },
    data() {
        return {
            perPage: 10,
            currentPage: 1,
            fields: [
                { key: "created", label: "Created" },
                { key: "totallicenses", label: "Total" },
                { key: "used", label: "Used" },
                { key: "online", label: "" },
                { key: "status", label: "" }
            ],
        }
    },
    mounted() {
        this.$store.dispatch("getCodeGroupList", { corpId: this.$route.params.corpId });
    },
    computed: {
        ...mapGetters(["codeGroupList"]),
        rows() {
            return this.codeGroupList.length;
        },
        corpid() {
            return this.$route.params.corpId;
        }
    },
}

</script>